import './easy_motion';
import './html';
import './latex';
import './marks';
import './text_formatting';
import './time_tracking';
import './todo';
import './recursive_expand';
import './daily_notes';

// for developers: uncomment the following lines
/*
import './debug_mode';
import './examples';
*/
// TODO: make this automatically happen in webpack dev?
